import React, { useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { 
  Button, Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import TechBaseTable from 'components/Table'
import TechBaseModal from 'components/Modal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CurrencyForm from './CurrencyForm'
import ConfirmationModal from 'components/Modal/confirmation'

import WithHOC from '../actions/currency'
import { ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CURRENCIES, COUNTRY_CODE, EXCHANGE_RATE } from 'locale/clients-label'
import { CREATE, EDIT, DELETE, ACTIONS } from 'locale/component-labels'

const CurrenciesConfig = props => {
  let{ 
    countries,
    selectedClient,
    getCountries,
    getCurrency
  } = props

  useEffect(()=> {
    getCountries()
  }, [])

  useEffect(() => {
    if( selectedClient ){
      getCurrency()
    }
  }, [ selectedClient ])

  const renderCurrencyTable = () => (
    <TechBaseTable
      data={ props.currencies }
      actionsContent={[
        {
          content: <i className="pe-7s-pen btn-icon-wrapper"/>,
          actionID: 'UpdateCurrency',
          color: 'primary',
          tooltipContent: EDIT,
          onClick: val => {
            props.onChangeCurrencyHOC( 'showUpdateCurrencyModal', true )
            props.getSelectedCurrency( selectedClient.id, val.countryCode )
          }
        },
        {
          content: <i className="pe-7s-trash btn-icon-wrapper"/>,
          actionID: 'DeleteCurrency',
          color: 'danger',
          hide: val => val.username === 'admin',
          tooltipContent: DELETE,
          onClick: val => {
            props.onChangeCurrencyHOC( 'selectedCurrency', val )
            props.onChangeCurrencyHOC( 'showDeleteCurrencyModal', true )
          }
        }
      ]}
      columnsContent={[
        {
          Header: COUNTRY_CODE,
          accessor: "countryCode"
        },
        {
          Header: EXCHANGE_RATE,
          accessor: "exchangeRate"
        }
      ]}
    />
  )

  const renderCreateCurrencyForm = () => (
    <TechBaseModal 
      size='lg' isOpen={ props.showCreateCurrencyModal }
      onClose={ () => props.onChangeCurrencyHOC( 'showCreateCurrencyModal', false )}
    >
      <CurrencyForm
        countries={ countries }
        onSubmit={ val => props.createCurrency( selectedClient.id, val )}
      />
      { props.onLoadCurrencies && <LoadingOverlay />}
    </TechBaseModal>
  )

  const renderUpdateCurrencyForm = () => (
    <TechBaseModal 
      size='lg' isOpen={ props.showUpdateCurrencyModal }
      onClose={ () => props.onChangeCurrencyHOC( 'showUpdateCurrencyModal', false )}
    >
      <CurrencyForm
        countries={ countries }
        selectedCurrency={ props.selectedCurrency }
        onSubmit={ val => props.updateCurrency( selectedClient.id, val )}
      />
      { props.onLoadCurrencies && <LoadingOverlay />}
    </TechBaseModal>
  )

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className='page-action-bar'>
          <div className="page-action-buttons">
            <Button 
              color='primary'
              onClick={ () => props.onChangeCurrencyHOC( 'showCreateCurrencyModal', true ) } 
            >
              <div className='d-flex align-items-center'>
                <FaPlus className='mr-2' style={{ width: 15, height: 15 }}/>
                { CREATE }
              </div>
            </Button>
          </div>
        </div>
        { renderCurrencyTable() }
        { renderCreateCurrencyForm() }
        { renderUpdateCurrencyForm() }
        { props.onLoadCurrencies && <LoadingOverlay />}
        <ConfirmationModal
          open={ props.showDeleteCurrencyModal }
          title={ ACTIONS }
          loading={ props.onLoadCurrencies }
          message={ ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CURRENCIES }
          onClose={ () => props.onChangeCurrencyHOC( 'showDeleteCurrencyModal', false )}
          onClickConfirm={ () => props.deleteCurrency( selectedClient.id, props.selectedCurrency.countryCode )}
        />
      </CardBody>
    </Card>
  )
}

export default WithHOC( CurrenciesConfig )