import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Tabs } from 'antd'
import _ from 'lodash'

import TiersForm from './Tiers'
import StyleForm from './Styles'
import GeneralForm from './General'
import PaymentGatewayForm from './PaymentGateway'
import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'
import { GENERAL, STYLES, TIERS, PAYMENT_GATEWAY } from 'locale/clients-label'
import { SUBMIT } from 'locale/component-labels'
import { DefaultFormValue } from '../assets'

const ClientForm = props => {
  const [ clientForm, updateClientForm ] = useState( DefaultFormValue )
  
  useEffect(()=>{
    if( props.selectedClient ){
      let tmp = _.cloneDeep( props.selectedClient )
      if( !tmp.logoImageUrl ){
        tmp.logo = { filename: "", base64: "" }
      }
      tmp.styles = JSON.stringify( tmp.styles )
      updateClientForm( tmp )
    }
  }, [ props.selectedClient ])
  
  const onChangeField = ( context, val ) => {
    let tmp = _.cloneDeep( clientForm )
    if( val !== undefined ){
      tmp[ context ] = val 
    } else {
      delete tmp[ context ]
    }
    updateClientForm( tmp )
  }

  let tabContent = [
    {
      key: '1',
      label: GENERAL,
      children: <GeneralForm users={ props.users } onChangeField={ onChangeField } clientForm={ clientForm }/>
    },
    {
      key: '2',
      label: TIERS,
      children: <TiersForm onChangeField={ onChangeField } clientForm={ clientForm }/>
    },
    {
      key: '3',
      label: PAYMENT_GATEWAY,
      children: <PaymentGatewayForm onChangeField={ onChangeField } clientForm={ clientForm }/>
    },
    {
      key: '4',
      label: STYLES,
      children: <StyleForm onChangeField={ onChangeField } clientForm={ clientForm }/>
    },
  ]
  
  return (
    <>
      <TechBaseModalBody>
        <Card>
          <CardBody>
            <Tabs defaultActiveKey="1" items={ tabContent }/>
          </CardBody>
        </Card>
      </TechBaseModalBody>
      <TechBaseModalFooter
        rightButton={[
          {
            color: 'primary',
            content: SUBMIT,
            onClick: () => props.onSubmit(clientForm)
          }
        ]}
      />
    </>
  )
}

export default ClientForm