import React, { useState } from 'react'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import _ from 'lodash'
import { UPLOAD, UPLOADING } from 'locale/clients-label'
import { toast } from 'react-toastify'

const getBase64 = ( img, callback ) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

const UploadImage = props => {
  let {
    clientForm,
    onChangeField
  } = props
  const [loading, setLoading] = useState(false)

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false)
        let tmp = _.cloneDeep( clientForm.logo )
        tmp.base64 = url
        onChangeField( 'logo', tmp )
      })
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      toast.error('You can only upload JPG/PNG file!')
    }
    return isJpgOrPng
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        { loading ? UPLOADING : UPLOAD }
      </div>
    </div>
  )

  return(
    <Upload
      name="avatar"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      beforeUpload={beforeUpload}
      customRequest={
        ({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok")
          }, 0)
        }
      }
      onChange={handleChange}
    >
      {
        clientForm.logo?.base64 ? (
          <img
            src={clientForm.logo?.base64}
            alt="logo"
            style={{
              width: '100%',
            }}
          />
        ) : (
          uploadButton
        )
      }
    </Upload>
  )
}

export default UploadImage