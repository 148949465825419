import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, Button,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import { TeamOutlined } from '@ant-design/icons'

import TechBaseTable from 'components/Table'
import TechBaseModal from 'components/Modal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SiteForm from './SiteForm'
import ConfirmationModal from 'components/Modal/confirmation'

import SitesHOC from './actions'

import { CREATE, EDIT, DELETE, ACTIONS } from 'locale/component-labels'
import SiteRolesConfig from './SiteRoles'
import { DOMAIN, NAME, ARE_YOU_SURE_TO_DELETE_THE_SELECTED_SITE, CLIENT, SITE_ROLES } from 'locale/sites-label'

class Sites extends Component {
  componentDidMount = () => {
    // const {
    //   can_read
    // } = permissionsChecker( this.props.moduleID, this.props.data.PermissionReducer.permisisonList.permissions )

    // if ( can_read ) {
    //   this.props.getSite()
    //   this.props.getSite(convertObjToBase64({page: 0}))
    // } else {
    //   this.props.history.push( '/dashboard' )
    // }
    this.props.getClient()
    this.props.getSite()
  }

  renderSiteTable = () => {
    return (
      <>
        <TechBaseTable
          data={ this.props.sites }
          actionsContent={[
            {
              content: <i className="pe-7s-pen btn-icon-wrapper"/>,
              actionID: 'UpdateSite',
              color: 'primary',
              tooltipContent: EDIT,
              onClick: val => {
                this.props.onChangeSiteHOC( 'showUpdateSiteModal', true )
                this.props.getSelectedSite( val.id )
              }
            },
            {
              content: <div className="d-flex align-items-center justify-content-center"><TeamOutlined /></div>,
              actionID: 'UpdateSiteRoles',
              color: 'primary',
              tooltipContent: SITE_ROLES,
              onClick: val => {
                this.props.onChangeSiteHOC( 'selectedSite', val )
                this.props.onChangeSiteHOC( 'showSiteRoleListModal', true )
              }
            },
            {
              content: <i className="pe-7s-trash btn-icon-wrapper"/>,
              actionID: 'DeleteSite',
              color: 'danger',
              hide: val => val.username === 'admin',
              tooltipContent: DELETE,
              onClick: val => {
                this.props.onChangeSiteHOC( 'selectedSite', val )
                this.props.onChangeSiteHOC( 'showDeleteSiteModal', true )
              }
            }
          ]}
          columnsContent={[
            {
              Header: NAME,
              accessor: "name"
            },
            {
              Header: DOMAIN,
              accessor: "domain"
            },
            {
              Header: CLIENT,
              Cell: val => (
                <div>{ _.find( this.props.clients, { value: val.clientId } )?.label }</div>
              )
            }
          ]}
        />
      </>
    )
  }

  renderCreateSiteForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showCreateSiteModal }
      onClose={ () => this.props.onChangeSiteHOC( 'showCreateSiteModal', false )}
    >
      <SiteForm
        clients={ this.props.clients }
        onSubmit={ val => this.props.createSite( val )}
      />
      { this.props.onLoadSites && <LoadingOverlay />}
    </TechBaseModal>
  )

  renderUpdateSiteForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showUpdateSiteModal }
      onClose={ () => this.props.onChangeSiteHOC( 'showUpdateSiteModal', false )}
    >
      <SiteForm
        clients={ this.props.clients }
        selectedSite={ this.props.selectedSite }
        onSubmit={ val => this.props.updateSite( val )}
      />
      { this.props.onLoadSites && <LoadingOverlay />}
    </TechBaseModal>
  )

  renderSiteRolesModal = () => (
    <TechBaseModal 
      size='lg' isOpen={ this.props.showSiteRoleListModal }
      onClose={ () => this.props.onChangeSiteHOC( 'showSiteRoleListModal', false )}
    >
      <SiteRolesConfig selectedSite={ this.props.selectedSite }/>
      { this.props.onLoadSites && <LoadingOverlay />}
    </TechBaseModal>
  )

  render = () => {

    return (
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <div className='page-action-bar'>
                    <div className="page-action-buttons">
                      <Button 
                        color='primary'
                        onClick={ () => this.props.onChangeSiteHOC( 'showCreateSiteModal', true ) } 
                      >
                        <div className='d-flex align-items-center'>
                          <FaPlus className='mr-2' style={{ width: 15, height: 15 }}/>
                          { CREATE }
                        </div>
                      </Button>
                    </div>
                  </div>
                  { this.renderSiteTable() }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {
          this.renderCreateSiteForm()
        }
        {
          this.renderUpdateSiteForm()
        }
        {
          this.renderSiteRolesModal()
        }
        { this.props.onLoadSites && <LoadingOverlay />}
        <ConfirmationModal
          open={ this.props.showDeleteSiteModal }
          title={ ACTIONS }
          loading={ this.props.onLoadSites }
          message={ ARE_YOU_SURE_TO_DELETE_THE_SELECTED_SITE }
          onClose={ () => this.props.onChangeSiteHOC( 'showDeleteSiteModal', false )}
          onClickConfirm={ () => this.props.deleteSite( this.props.selectedSite.id )}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default compose(
  SitesHOC,
  connect( mapStateToProps )
)(Sites)