export const NAME = "Name"
export const EMAIL = "Email"
export const STATUS = "Status"
export const USERNAME = "Username"
export const ADMIN_PANEL_LANGUAGE = "Admin Panel Language"
export const NEW_PASSWORD = "New Password"
export const CONFIRM_PASSWORD = "Confirm Password"
export const PASSWORD_NEEDS_TO_HAVE_AT_LEAST_EIGHT_CHARACTERS = "Password needs to have at least eight characters"
export const CONFIRM_PASSWORD_IS_NOT_THE_SAME = "Confirm password is not the same"
export const PASSWORD_UPDATE_SUCCESSFUL = "Password Update Successful"
export const CHANGE_PASSWORD = "Change Password"
export const ARE_YOU_SURE_TO_DELETE_THE_SELECTED_USER = "Are you sure to delete the selected user?"
export const GENDER = "Gender"
export const MALE = "Male"
export const FEMALE = "Female"
export const PASSWORD = "Password"
export const ROLE = "Role"
export const SUPERADMIN = "Super Admin"
export const ADMIN = "Admin"
export const CLIENT = "Client"
