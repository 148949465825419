import Axios from 'axios'
import { toast } from 'react-toastify'
import { getItem, clearItem } from 'utils/tokenStore'

import { GET_PROFILE } from './types'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './Ajax'
import getDomainURL from 'utils/api'

export const getProfile = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'TECHBASE_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/auth/current_user`
  ).then( response => {
    dispatch( getProfileSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    if( error.response.status === 401 ){
      toast.error( 'Due to inactivity, your session has expired, please login again.' )
    }
    dispatch( ajaxCallError( error.response.data.message ) )
    clearItem( 'TECHBASE_TOKEN' )
  })
}

export const getProfileSuccess = payload => ({
  type: GET_PROFILE,
  payload
})