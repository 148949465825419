import React from 'react'
import { 
  Form, Label, FormGroup,
  Row, Col
} from 'reactstrap'
import { Button, Card, Input, InputNumber } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import _ from 'lodash'

import { ADD_NEW_TIER, KEY, NAME, PERIOD_AMOUNT, PERIOD_TIMES, PERIOD_UNIT, POINTS_ACCUMULATED, PROFIT_TYPE } from 'locale/clients-label'

let defaultTier = {
  id: "",
  name: "",
  conditions: {
    profitType: "",
    pointsAccumulated: 0,
    periodAmount: 0,
    periodUnit: "",
    periodTimes: 0
  }
}

const TiersForm = props => {
  let {
    clientForm,
    onChangeField
  } = props

  return(
    <Form>
      <Button 
        onClick={ () => {
          let tmp = _.cloneDeep( clientForm.tiers )
          tmp.push({ ...defaultTier })
          onChangeField( 'tiers', tmp )
        }}>
        { ADD_NEW_TIER }
      </Button>
      {
        clientForm.tiers.map( ( tier, index ) => (
          <Card className="my-3">
            <Button 
              type="primary" 
              shape="circle" 
              danger
              className='d-flex justify-content-center align-items-center'
              style={{ flexWrap: 'wrap', position: 'absolute', top: '-15px', right: '-15px' }}
              onClick={ () => {
                let tmp = _.cloneDeep( clientForm.tiers )
                tmp.splice( index, 1 )
                onChangeField( 'tiers', tmp )
              }}>
              <DeleteFilled />
            </Button>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ KEY }</Label>
                  <Input
                    value={ tier.id }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].id = e.target.value
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ NAME }</Label>
                  <Input
                    value={ tier.name }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].name = e.target.value
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ PROFIT_TYPE }</Label>
                  <Input
                    value={ tier.conditions.profitType }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].conditions.profitType = e.target.value
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ POINTS_ACCUMULATED }</Label>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={ tier.conditions.pointsAccumulated }
                    onChange={ val => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].conditions.pointsAccumulated = val
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ PERIOD_AMOUNT }</Label>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={ tier.conditions.periodAmount }
                    onChange={ val => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].conditions.periodAmount = val
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ PERIOD_UNIT }</Label>
                  <Input
                    value={ tier.conditions.periodUnit }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].conditions.periodUnit = e.target.value
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ PERIOD_TIMES }</Label>
                  <InputNumber
                    style={{ width: "100%" }}
                    value={ tier.conditions.periodTimes }
                    onChange={ val => {
                      let tmp = _.cloneDeep( clientForm.tiers )
                      tmp[ index ].conditions.periodTimes = val
                      onChangeField( 'tiers', tmp )
                    }}/>
                </FormGroup>
              </Col>
            </Row>
          </Card>
        ))
      }
    </Form>
  )
}

export default TiersForm