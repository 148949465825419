import { USERS, CLIENTS } from "locale/side-menu-labels"

export const pageNamesMappings = [
  {
    path: "/dashboard/users",
    pageName: USERS
  },
  {
    path: "/dashboard/clients",
    pageName: CLIENTS
  }
]