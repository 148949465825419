import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, Button,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import TechBaseTable from 'components/Table'
import TechBaseModal from 'components/Modal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import UserForm from './UserForm'
import ConfirmationModal from 'components/Modal/confirmation'
// import PasswordModal from './Password'

import UsersHOC from './actions/index'

import { EMAIL, ARE_YOU_SURE_TO_DELETE_THE_SELECTED_USER, USERNAME, GENDER, ROLE } from 'locale/users-label'
import { CREATE, EDIT, DELETE, ACTIONS } from 'locale/component-labels'

class Users extends Component {
  componentDidMount = () => {
    // const {
    //   can_read
    // } = permissionsChecker( this.props.moduleID, this.props.data.PermissionReducer.permisisonList.permissions )

    // if ( can_read ) {
    //   this.props.getUser()
    //   this.props.getClient(convertObjToBase64({page: 0}))
    // } else {
    //   this.props.history.push( '/dashboard' )
    // }

    this.props.getUser()
  }

  // componentDidUpdate = pp => {
  //   let tempSearchParams = _.cloneDeep( this.props.searchParams.advance )
  //   let tempIndex = _.findIndex( tempSearchParams, { value: 'role_id' })

  //   if ( 
  //       tempSearchParams[ tempIndex ][ 'options' ].length < 1 &&
  //       this.props.data.DictionaryReducer.roles.length > 0
  //     ) {
  //     tempSearchParams[ tempIndex ][ 'options' ] = this.props.data.DictionaryReducer.roles
  //     this.props.onChangeUserHOC( 'searchParams', {
  //       ... this.props.searchParams,
  //       ['advance']: tempSearchParams
  //     })
  //   }
  // }

  renderUserTable = () => {

    return (
      <>
        <TechBaseTable
          data={ this.props.users }
          actionsContent={[
            {
              content: <i className="pe-7s-pen btn-icon-wrapper"/>,
              actionID: 'UpdateUser',
              color: 'primary',
              tooltipContent: EDIT,
              onClick: val => {
                this.props.onChangeUserHOC( 'showUpdateUserModal', true )
                this.props.getSelectedUser( val.id )
              }
            },
            // {
            //   content: <i className="pe-7s-key btn-icon-wrapper"/>,
            //   actionID: 'UpdateUserPassword',
            //   color: 'success',
            //   tooltipContent: CHANGE_PASSWORD,
            //   onClick: val => {
            //     this.props.onChangeUserHOC( 'selectedUser', val )
            //     this.props.onChangeUserHOC( 'showUpdatePasswordModal', true )
            //   }
            // },
            {
              content: <i className="pe-7s-trash btn-icon-wrapper"/>,
              actionID: 'DeleteUser',
              color: 'danger',
              hide: val => val.username === 'admin',
              tooltipContent: DELETE,
              onClick: val => {
                this.props.onChangeUserHOC( 'selectedUser', val )
                this.props.onChangeUserHOC( 'showDeleteUserModal', true )
              }
            }
          ]}
          columnsContent={[
            {
              Header: USERNAME,
              accessor: "username"
            },
            {
              Header: EMAIL,
              accessor: "email"
            },
            {
              Header: GENDER,
              accessor: "gender"
            },
            {
              Header: ROLE,
              accessor: "role"
            }
          ]}
        />
      </>
    )
  }

  renderCreateUserForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showCreateUserModal }
      onClose={ () => this.props.onChangeUserHOC( 'showCreateUserModal', false )}
    >
      <UserForm
        onSubmit={ val => this.props.createUser( val )}
      />
      { this.props.onLoadUsers && <LoadingOverlay />}
    </TechBaseModal>
  )

  renderUpdateUserForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showUpdateUserModal }
      onClose={ () => this.props.onChangeUserHOC( 'showUpdateUserModal', false )}
    >
      <UserForm
        selectedUser={ this.props.selectedUser }
        onSubmit={ val => this.props.updateUser( val )}
      />
      { this.props.onLoadUsers && <LoadingOverlay />}
    </TechBaseModal>
  )

  // renderUpdatePasswordForm = () => (
  //   <TechBaseModal 
  //     size='md' isOpen={ this.props.showUpdatePasswordModal }
  //     onClose={ () => this.props.onChangeUserHOC( 'showUpdatePasswordModal', false )}
  //   >
  //     <PasswordModal { ...this.props } />
  //     { this.props.onLoadUsers && <LoadingOverlay/> }
  //   </TechBaseModal>
  // )

  render = () => {

    return (
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <div className='page-action-bar'>
                    <div className="page-action-buttons">
                      <Button 
                        color='primary'
                        onClick={ () => this.props.onChangeUserHOC( 'showCreateUserModal', true ) } 
                      >
                        <div className='d-flex align-items-center'>
                          <FaPlus className='mr-2' style={{ width: 15, height: 15 }}/>
                          { CREATE }
                        </div>
                      </Button>
                    </div>
                  </div>
                  { this.renderUserTable() }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {
          this.renderCreateUserForm()
        }
        {
          this.renderUpdateUserForm()
        }
        {/* {
          this.renderUpdatePasswordForm()
        } */}
        { this.props.onLoadUsers && <LoadingOverlay />}
        <ConfirmationModal
          open={ this.props.showDeleteUserModal }
          title={ ACTIONS }
          loading={ this.props.onLoadUsers }
          message={ ARE_YOU_SURE_TO_DELETE_THE_SELECTED_USER }
          onClose={ () => this.props.onChangeUserHOC( 'showDeleteUserModal', false )}
          onClickConfirm={ () => this.props.deleteUser( this.props.selectedUser.id )}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default compose(
  UsersHOC,
  connect( mapStateToProps )
)(Users)