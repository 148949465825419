import React, { useState, useEffect } from 'react'
import { 
  Form, Label, FormGroup, Input,
  Row, Col,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'

import { SUBMIT } from 'locale/component-labels'

import { DefaultFormValue } from './assets'
import { ADMIN, CLIENT, CONFIRM_PASSWORD, EMAIL, GENDER, PASSWORD, SUPERADMIN, USERNAME, ROLE, MALE, FEMALE } from 'locale/users-label'

const UserForm = props => {
  const [ userForm, updateUserForm ] = useState( DefaultFormValue )

  useEffect(()=>{
    if( props.selectedUser ){
      updateUserForm( props.selectedUser )
    }
  }, [ props.selectedUser ])
  
  const onChangeField = ( context, val ) => {
    let tmp = _.cloneDeep( userForm )
    tmp[ context ] = val 
    updateUserForm( tmp )
  }
  
  return (
    <>
      <TechBaseModalBody>
        <Card>
          <CardBody>
            <Form>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{ USERNAME }</Label>
                    <Input
                      value={ userForm.username }
                      onChange={ e => onChangeField( 'username', e.target.value ) }/>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{ EMAIL }</Label>
                    <Input
                      value={ userForm.email }
                      onChange={ e => onChangeField( 'email', e.target.value ) }/>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{ GENDER }</Label>
                    <Input
                      type="select"
                      value={ userForm.gender }
                      onChange={ e => onChangeField( 'gender', e.target.value ) }>
                      <option></option>
                      <option value="male">{ MALE }</option>
                      <option value="female">{ FEMALE }</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label>{ ROLE }</Label>
                    <Input
                      type="select"
                      value={ userForm.role }
                      onChange={ e => onChangeField( 'role', e.target.value ) }>
                      <option></option>
                      <option value="Superadmin">{ SUPERADMIN }</option>
                      <option value="Admin">{ ADMIN }</option>
                      <option value="Client">{ CLIENT }</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {
                !props.selectedUser && (
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label>{ PASSWORD }</Label>
                        <Input
                          type="password"
                          value={ userForm.password }
                          onChange={ e => onChangeField( 'password', e.target.value ) }/>
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Label>{ CONFIRM_PASSWORD }</Label>
                        <Input
                          type="password"
                          value={ userForm.confirm_password }
                          onChange={ e => onChangeField( 'confirm_password', e.target.value ) }/>
                      </FormGroup>
                    </Col>
                  </Row>
                )
              }
            </Form>
          </CardBody>
        </Card>
      </TechBaseModalBody>
      <TechBaseModalFooter
        rightButton={[
          {
            color: 'primary',
            content: SUBMIT,
            onClick: () => props.onSubmit( userForm )
          }
        ]}
      />
    </>
  )
}

export default UserForm