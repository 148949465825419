import React, { useState, useEffect } from 'react'
import { 
  Label, FormGroup, Input,
  Row, Col,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'

import { SUBMIT } from 'locale/component-labels'
import { CLIENT, DOMAIN, NAME } from 'locale/sites-label'

const SiteForm = props => {
  const [ siteForm, updateSiteForm ] = useState({ name: "", domain: "", clientId: 0 })

  useEffect(()=>{
    if( props.selectedSite ){
      updateSiteForm( props.selectedSite )
    }
  }, [ props.selectedSite ])
  
  const onChangeField = ( context, val ) => {
    let tmp = _.cloneDeep( siteForm )
    tmp[ context ] = val 
    updateSiteForm( tmp )
  }
  
  return (
    <>
      <TechBaseModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ NAME }</Label>
                  <Input
                    value={ siteForm.name }
                    onChange={ e => onChangeField( 'name', e.target.value ) }/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ DOMAIN }</Label>
                  <Input
                    value={ siteForm.domain }
                    onChange={ e => onChangeField( 'domain', e.target.value ) }/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ CLIENT }</Label>
                  <Input
                    type="select"
                    value={ siteForm.clientId }
                    onChange={ e => onChangeField( 'clientId', parseInt( e.target.value ) ) }>
                    <option></option>
                    {
                      props.clients.map( client => (
                        <option value={ client.value }>{ client.label }</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </TechBaseModalBody>
      <TechBaseModalFooter
        rightButton={[
          {
            color: 'primary',
            content: SUBMIT,
            onClick: () => props.onSubmit( siteForm )
          }
        ]}
      />
    </>
  )
}

export default SiteForm