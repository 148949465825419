import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { connect } from 'react-redux'
import { CREATE_SUCCESSFUL, DELETE_SUCCESSFUL, UPDATE_SUCCESSFUL } from 'locale/component-labels'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      clients: [],
      sites: [],
      selectedSite: {},
      showCreateSiteModal: false,
      showDeleteSiteModal: false,
      showUpdateSiteModal: false,
      showSiteRoleListModal: false
    }

    onChangeSiteHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getClient = () => {
      Get(
        `/clients`,
        this.getClientSuccess,
        this.getClientError,
        this.load
      )
    }

    getClientSuccess = payload => {
      this.setState({ clients: _.map( _.map( payload, "client" ), client => { return { label: client.name, value: client.id }} ) })
    }
    getClientError = error => requestError( error )

    getSite = () => {
      Get(
        `/sites`,
        this.getSiteSuccess,
        this.getSiteError,
        this.load
      )
    }

    getSiteSuccess = payload => this.setState({ sites: payload })
    getSiteError = error => requestError( error )

    getSelectedSite = id => Get(
      `/sites/${ id }`,
      this.getSelectedSiteSuccess,
      this.getSelectedSiteError,
      this.load
    )
    getSelectedSiteSuccess = payload => {
      let temp = _.cloneDeep( payload )
      delete temp.updatedAt
      delete temp.createdAt

      this.setState({ selectedSite: temp })
    }
    getSelectedSiteError = error => requestError ( error )

    createSite = dataToSubmit => Post(
      `/sites`,
      dataToSubmit,
      this.createSiteSuccess,
      this.createSiteError,
      this.load
    )
    createSiteSuccess = () => {
      this.getSite()
      this.setState({ showCreateSiteModal: false })
      requestSuccess( CREATE_SUCCESSFUL )
    }
    createSiteError = error => requestError( error )

    updateSite = dataToSubmit => Put(
      `/sites/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSiteSuccess,
      this.updateSiteError,
      this.load
    )
    updateSiteSuccess = payload => {
      this.getSite()
      this.getSelectedSite( payload.id )
      this.setState({ 
        showUpdateSiteModal: false
      })
      requestSuccess( UPDATE_SUCCESSFUL )
    }
    updateSiteError = error => requestError( error )

    deleteSite = ( id ) => Delete(
      `/sites/${ id }`,
      this.deleteSiteSuccess,
      this.deleteSiteError,
      this.load
    )
    deleteSiteSuccess = () => {
      this.getSite()
      this.setState({
        showDeleteSiteModal: false
      })
      requestSuccess( DELETE_SUCCESSFUL )
    }
    deleteSiteError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadSites={ this.state.loading }
          clients={ this.state.clients }
          sites={ this.state.sites }
          selectedSite={ this.state.selectedSite }
          showCreateSiteModal={ this.state.showCreateSiteModal }
          showDeleteSiteModal={ this.state.showDeleteSiteModal }
          showUpdateSiteModal={ this.state.showUpdateSiteModal }
          showSiteRoleListModal={ this.state.showSiteRoleListModal }
          getClient={ this.getClient }
          getSite={ this.getSite }
          getSelectedSite={ this.getSelectedSite }
          createSite={ this.createSite }
          updateSite={ this.updateSite }
          deleteSite={ this.deleteSite }
          // updateSitePassword={ this.updateSitePassword }
          onChangeSiteHOC={ this.onChangeSiteHOC } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC