import { GET_PROFILE } from 'actions/types'

const initialState = {
  profile: {
    Roles: [],
    admin_panel_language: "en",
    email: '',
    last_name: "",
    first_name: '',
    status_id: 1
  }
}


export default ( state = initialState, action ) => {
  switch( action.type ) {
    case GET_PROFILE: 
      return {
        ...state,
        profile: action.payload
      }
    default: return state
  }
}