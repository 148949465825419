import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import ConfigureStore from "./store/config"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import { ToastContainer } from 'react-toastify'

import "./index.css"
import "./stylesheets/base.scss"

const store = ConfigureStore()
const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <ToastContainer/>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
