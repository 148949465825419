import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input

const StyleForm = props => {
  let {
    clientForm,
    onChangeField
  } = props
  
  return(
    <TextArea
      autoSize
      value={ clientForm.styles }
      onChange={ e => onChangeField( 'styles', e.target.value ) }/>
  )
}

export default StyleForm