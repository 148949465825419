import React from "react"
import { HashRouter } from "react-router-dom"

import TechBaseMasterRouter from "./router"

import './App.css'

function App() {
  return (
    <HashRouter>
      <TechBaseMasterRouter />
    </HashRouter>
  )
}

export default App
