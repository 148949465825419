import React, { Component } from 'react'
import { connect } from 'react-redux'

import getDomainURL from 'utils/api'
import { storeItem } from 'utils/tokenStore'
import { getProfile } from 'actions/profile' 
import { getCipherHeader } from 'utils/cipher-text'

import Axios from 'axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showPassword: false,
      showErrorMessage: false
    }

    onChangeHOC = ( val, context ) => this.setState({ [context]: val })

    onClickLogin = ( data) => {
      this.setState({ loading: true }, () => {
        Axios.post(
          `${getDomainURL()}/auth/user/login`, 
          data,
          { headers: { 
            'Accept-Language': '',
            'Authorization': getCipherHeader() 
          }}
          ).then( response => {
            this.setState({ loading: false })
            storeItem( 'TECHBASE_TOKEN', response.data.token )
            Promise.all([
              this.props.getProfile()
            ]).then(() => {
              this.props.history.push( '/dashboard' )
            })
          }).catch( error => {
            this.setState({ showErrorMessage: true, loading: false })
        })
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ... this.props }
          showPassword={ this.state.showPassword }
          showErrorMessage={ this.state.showErrorMessage }
          onLoadLogin={ this.state.loading }
          onChangeHOC={ this.onChangeHOC }
          onClickLogin={ this.onClickLogin }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps, {
    getProfile
  })( WithHOC )
  // return WithHOC
}
export default HOC