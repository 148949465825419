import { ENGLISH, MALAY, MANDARIN } from "locale/clients-label"

export const DefaultFormValue = {
  userId: "",
  name: "",
  styles: "{}",
  language: [],
  paymentGateway: [],
  tiers: [],
  logo: {
    base64: "",
    filename: ""
  },
  websiteUrl: "",
  pointName: "",
  mailgunApiKey: "",
  isSellProductAtRegistration: false,
  isSellPackageAtRegistration: false
}

export const LanguageOptions = [
  {
    label: ENGLISH,
    value: "en"
  },
  {
    label: MALAY,
    value: "ms"
  },
  {
    label: MANDARIN,
    value: "zh"
  }
]