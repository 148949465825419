import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { CREATE_SUCCESSFUL, DELETE_SUCCESSFUL, UPDATE_SUCCESSFUL } from 'locale/component-labels'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      countries: [],
      currencies: [],
      selectedCurrency: {},
      showCreateCurrencyModal: false,
      showDeleteCurrencyModal: false,
      showUpdateCurrencyModal: false
    }

    onChangeCurrencyHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCountries = () => {
      Get(
        `/countries`,
        this.getCountriesSuccess,
        this.getCountriesError,
        this.load,
        true
      )
    }

    getCountriesSuccess = payload => {
      this.setState({ countries: _.map( payload, country => { return { label: country.name, value: country.iso2 }}) })
    }
    getCountriesError = error => requestError( error )

    getCurrency = () => {
      Get(
        `/clients/${ this.props.selectedClient.id }/countries`,
        this.getCurrencySuccess,
        this.getCurrencyError,
        this.load,
        true
      )
    }

    getCurrencySuccess = payload => this.setState({ currencies: payload })
    getCurrencyError = error => requestError( error )

    getSelectedCurrency = ( client_id, country_code ) => Get(
      `/clients/${ client_id }/countries/${ country_code }`,
      this.getSelectedCurrencySuccess,
      this.getSelectedCurrencyError,
      this.load,
      true
    )
    getSelectedCurrencySuccess = payload => {
      let temp = _.cloneDeep( payload )
      delete temp.updatedAt
      delete temp.createdAt

      this.setState({ selectedCurrency: temp })
    }
    getSelectedCurrencyError = error => requestError ( error )

    createCurrency = ( client_id, dataToSubmit ) => Post(
      `/clients/${ client_id }/countries`,
      { clientId: client_id, ...dataToSubmit },
      this.createCurrencySuccess,
      this.createCurrencyError,
      this.load
    )
    createCurrencySuccess = () => {
      this.getCurrency()
      this.setState({ showCreateCurrencyModal: false })
      requestSuccess( CREATE_SUCCESSFUL )
    }
    createCurrencyError = error => requestError( error )

    updateCurrency = ( client_id, dataToSubmit ) => Put(
      `/clients/${ client_id }/countries/${ dataToSubmit.countryCode }`,
      dataToSubmit,
      this.updateCurrencySuccess,
      this.updateCurrencyError,
      this.load
    )
    updateCurrencySuccess = payload => {
      this.getCurrency()
      this.setState({ 
        showUpdateCurrencyModal: false
      })
      requestSuccess( UPDATE_SUCCESSFUL )
    }
    updateCurrencyError = error => requestError( error )

    deleteCurrency = ( client_id, country_code ) => Delete(
      `/clients/${ client_id }/countries/${ country_code }`,
      this.deleteCurrencySuccess,
      this.deleteCurrencyError,
      this.load
    )
    deleteCurrencySuccess = () => {
      this.getCurrency()
      this.setState({
        showDeleteCurrencyModal: false
      })
      requestSuccess( DELETE_SUCCESSFUL )
    }
    deleteCurrencyError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadCurrencies={ this.state.loading }
          countries={ this.state.countries }
          currencies={ this.state.currencies }
          selectedCurrency={ this.state.selectedCurrency }
          showCreateCurrencyModal={ this.state.showCreateCurrencyModal }
          showDeleteCurrencyModal={ this.state.showDeleteCurrencyModal }
          showUpdateCurrencyModal={ this.state.showUpdateCurrencyModal }
          showUpdatePasswordModal={ this.state.showUpdatePasswordModal }
          getCountries={ this.getCountries }
          getCurrency={ this.getCurrency }
          getSelectedCurrency={ this.getSelectedCurrency }
          createCurrency={ this.createCurrency }
          updateCurrency={ this.updateCurrency }
          deleteCurrency={ this.deleteCurrency }
          onChangeCurrencyHOC={ this.onChangeCurrencyHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC