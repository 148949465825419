import React, { useEffect } from 'react'
import { FaPlus } from 'react-icons/fa'
import { 
  Button, Card, CardBody
} from 'reactstrap'
import _ from 'lodash'

import TechBaseTable from 'components/Table'
import TechBaseModal from 'components/Modal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import SiteRoleForm from './SiteRoleForm'
import ConfirmationModal from 'components/Modal/confirmation'

import WithHOC from '../actions/siteRole'
import { CREATE, EDIT, DELETE, ACTIONS } from 'locale/component-labels'
import { NAME, REMARK, ARE_YOU_SURE_TO_DELETE_THE_SELECTED_SITE_ROLE } from 'locale/sites-label'

const SiteRolesiesConfig = props => {
  let{
    selectedSite,
    getSiteRole
  } = props

  useEffect(() => {
    if( selectedSite ){
      getSiteRole()
    }
  }, [ selectedSite ])

  const renderSiteRoleTable = () => (
    <TechBaseTable
      data={ props.siteRoles }
      actionsContent={[
        {
          content: <i className="pe-7s-pen btn-icon-wrapper"/>,
          actionID: 'UpdateSiteRole',
          color: 'primary',
          tooltipContent: EDIT,
          onClick: val => {
            props.onChangeSiteRoleHOC( 'showUpdateSiteRoleModal', true )
            props.getSelectedSiteRole( selectedSite.id, val.id )
          }
        },
        {
          content: <i className="pe-7s-trash btn-icon-wrapper"/>,
          actionID: 'DeleteSiteRole',
          color: 'danger',
          hide: val => val.username === 'admin',
          tooltipContent: DELETE,
          onClick: val => {
            props.onChangeSiteRoleHOC( 'selectedSiteRole', val )
            props.onChangeSiteRoleHOC( 'showDeleteSiteRoleModal', true )
          }
        }
      ]}
      columnsContent={[
        {
          Header: NAME,
          accessor: "name"
        },
        {
          Header: REMARK,
          accessor: "remark"
        }
      ]}
    />
  )

  const renderCreateSiteRoleForm = () => (
    <TechBaseModal 
      size='lg' isOpen={ props.showCreateSiteRoleModal }
      onClose={ () => props.onChangeSiteRoleHOC( 'showCreateSiteRoleModal', false )}
    >
      <SiteRoleForm
        onSubmit={ val => props.createSiteRole( selectedSite.id, val )}
      />
      { props.onLoadSiteRolesies && <LoadingOverlay />}
    </TechBaseModal>
  )

  const renderUpdateSiteRoleForm = () => (
    <TechBaseModal 
      size='lg' isOpen={ props.showUpdateSiteRoleModal }
      onClose={ () => props.onChangeSiteRoleHOC( 'showUpdateSiteRoleModal', false )}
    >
      <SiteRoleForm
        selectedSiteRole={ props.selectedSiteRole }
        onSubmit={ val => props.updateSiteRole( selectedSite.id, val )}
      />
      { props.onLoadSiteRolesies && <LoadingOverlay />}
    </TechBaseModal>
  )

  return (
    <Card className="main-card mb-3">
      <CardBody>
        <div className='page-action-bar'>
          <div className="page-action-buttons">
            <Button 
              color='primary'
              onClick={ () => props.onChangeSiteRoleHOC( 'showCreateSiteRoleModal', true ) } 
            >
              <div className='d-flex align-items-center'>
                <FaPlus className='mr-2' style={{ width: 15, height: 15 }}/>
                { CREATE }
              </div>
            </Button>
          </div>
        </div>
        { renderSiteRoleTable() }
        { renderCreateSiteRoleForm() }
        { renderUpdateSiteRoleForm() }
        { props.onLoadSiteRolesies && <LoadingOverlay />}
        <ConfirmationModal
          open={ props.showDeleteSiteRoleModal }
          title={ ACTIONS }
          loading={ props.onLoadSiteRolesies }
          message={ ARE_YOU_SURE_TO_DELETE_THE_SELECTED_SITE_ROLE }
          onClose={ () => props.onChangeSiteRoleHOC( 'showDeleteSiteRoleModal', false )}
          onClickConfirm={ () => props.deleteSiteRole( selectedSite.id, props.selectedSiteRole.id )}
        />
      </CardBody>
    </Card>
  )
}

export default WithHOC( SiteRolesiesConfig )