export const PREVIOUS = "Previous"
export const NEXT = "Next"
export const PAGES = "Pages"
export const NO_CONTENT_CREATED = "No Content Created"
export const ACTIONS = "Actions"
export const ACCOUNT = "Account"
export const SIDEBAR = "Sidebar"
export const CONFIRM = "Confirm"
export const SEARCH = "Search"
export const RESET = "Reset"
export const VIEW_MORE = "View More"
export const SEARCH_BY = "Search By"
export const CREATE_SUCCESSFUL = "Create Successful"
export const UPDATE_SUCCESSFUL = "Update Successful"
export const DELETE_SUCCESSFUL = "Delete Successful"
export const SELECT = "Select"
export const SUBMIT = "Submit"
export const CREATE = "Create"
export const DELETE = "Delete"
export const EDIT = "Edit"

