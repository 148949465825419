import React from 'react'
import {
  Label
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TechBaseModal from 'components/Modal'
import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'

const Confirmation = ({
  open,
  title,
  loading,
  message,

  onClose,
  onClickConfirm
  }) => {
    return (
      <TechBaseModal 
        isOpen={ open }
        onClose={ () => onClose()}
      >
        <TechBaseModalBody>
          <Label>{ message }</Label>
        </TechBaseModalBody>
        <TechBaseModalFooter
          rightButton={[
            {
              content: 'Confirm',
              onClick: () => onClickConfirm()
            }
          ]}
        />
        { loading && <LoadingOverlay/> }
      </TechBaseModal>
    )
  }
  
  export default Confirmation