import React from 'react'
import TemplateContainerMain from 'components/Template'
import TemplateHeader from 'components/Header'
import TemplateSidemenu from 'components/Menu/Sidemenu'
import { Route, withRouter } from 'react-router-dom'

import UsersMngmt from './Users'
import ClientsMngmt from './Clients'
import SitesMngmt from './Sites'

const Dashboard = props => {
  return (
    <TemplateContainerMain>
      <TemplateHeader
        onClickToggleProfileModal= { () => {} }
        history={ props.history }
        user={{
          name: 'Test user',
          email: 'testuser@gmail.com'
        }} />
      <div className="app-main">
        <TemplateSidemenu 
          userPermissions={ props.userPermissions }
          user={ props.user }
          userRole={ props.userRole } />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <Route path={ `/dashboard/users` } render={ props => ( <UsersMngmt { ...props } /> )} />
            <Route path={ `/dashboard/clients` } render={ props => ( <ClientsMngmt { ...props } /> )} />
            <Route path={ `/dashboard/sites` } render={ props => ( <SitesMngmt { ...props } /> )} />
          </div>
        </div>
      </div>
    </TemplateContainerMain>
  )
}
 
export default withRouter( Dashboard )