import React, { useState, useEffect } from 'react'
import { Card, CardBody, FormGroup, Label, Row, Col, Input } from 'reactstrap'
import _ from 'lodash'

import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'
import { SUBMIT } from 'locale/component-labels'
import { COUNTRY_CODE, EXCHANGE_RATE } from 'locale/clients-label'

const CurrencyForm = props => {
  const [ currencyForm, updateCurrencyForm ] = useState({
    countryCode: "",
    exchangeRate: 0
  })
  
  useEffect(()=>{
    if( props.selectedCurrency ){
      updateCurrencyForm( props.selectedCurrency )
    }
  }, [ props.selectedCurrency ])
  
  const onChangeField = ( context, val ) => {
    let tmp = _.cloneDeep( currencyForm )
    if( val !== undefined ){
      tmp[ context ] = val 
    } else {
      delete tmp[ context ]
    }
    updateCurrencyForm( tmp )
  }
  
  return (
    <>
      <TechBaseModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ COUNTRY_CODE }</Label>
                  <Input
                    type="select"
                    disabled={ props.selectedCurrency }
                    value={ currencyForm.countryCode }
                    onChange={ e => onChangeField( 'countryCode', e.target.value ) }>
                    <option></option>
                    {
                      props.countries.map( country => (
                        <option value={ country.value }>{ country.label }</option>
                      ))
                    }
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ EXCHANGE_RATE }</Label>
                  <Input
                    type="number"
                    value={ currencyForm.exchangeRate }
                    onChange={ e => onChangeField( 'exchangeRate', parseFloat( e.target.value ) ) }/>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </TechBaseModalBody>
      <TechBaseModalFooter
        rightButton={[
          {
            color: 'primary',
            content: SUBMIT,
            onClick: () => props.onSubmit( currencyForm )
          }
        ]}
      />
    </>
  )
}

export default CurrencyForm