import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { FaPlus } from 'react-icons/fa'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Row, Col, Button,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import { DollarCircleFilled } from '@ant-design/icons'

import TechBaseTable from 'components/Table'
import TechBaseModal from 'components/Modal'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ClientForm from './ClientForm'
import ConfirmationModal from 'components/Modal/confirmation'

import ClientsHOC from './actions/index'

import { NAME, WEBSITE_URL, ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CLIENT, CURRENCIES, USER } from 'locale/clients-label'
import { CREATE, EDIT, DELETE, ACTIONS } from 'locale/component-labels'
import CurrenciesConfig from './Currencies'

class Clients extends Component {
  componentDidMount = () => {
    this.props.getUser()
    this.props.getClient()
  }

  renderClientTable = () => {
    return (
      <>
        <TechBaseTable
          data={ this.props.clients }
          actionsContent={[
            {
              content: <i className="pe-7s-pen btn-icon-wrapper"/>,
              actionID: 'UpdateClient',
              color: 'primary',
              tooltipContent: EDIT,
              onClick: val => {
                this.props.onChangeClientHOC( 'showUpdateClientModal', true )
                this.props.getSelectedClient( val.id )
              }
            },
            {
              content: <div className="d-flex align-items-center justify-content-center"><DollarCircleFilled /></div>,
              actionID: 'UpdateCurrencies',
              color: 'primary',
              tooltipContent: CURRENCIES,
              onClick: val => {
                this.props.onChangeClientHOC( 'selectedClient', val )
                this.props.onChangeClientHOC( 'showCurrencyListModal', true )
              }
            },
            {
              content: <i className="pe-7s-trash btn-icon-wrapper"/>,
              actionID: 'DeleteClient',
              color: 'danger',
              hide: val => val.username === 'admin',
              tooltipContent: DELETE,
              onClick: val => {
                this.props.onChangeClientHOC( 'selectedClient', val )
                this.props.onChangeClientHOC( 'showDeleteClientModal', true )
              }
            }
          ]}
          columnsContent={[
            {
              Header: NAME,
              accessor: "name"
            },
            {
              Header: WEBSITE_URL,
              accessor: "websiteUrl"
            },
            {
              Header: USER,
              Cell: val => (
                <div>{ _.find( this.props.users, { value: val.userId } )?.label }</div>
              )
            }
          ]}
        />
      </>
    )
  }

  renderCreateClientForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showCreateClientModal }
      onClose={ () => this.props.onChangeClientHOC( 'showCreateClientModal', false )}
    >
      <ClientForm
        users={ this.props.users }
        onSubmit={ val => {
          let tmp = _.cloneDeep( val )
          tmp.styles = JSON.parse( tmp.styles )
          this.props.createClient( tmp )
        }}
      />
      { this.props.onLoadClients && <LoadingOverlay />}
    </TechBaseModal>
  )

  renderUpdateClientForm = () => (
    <TechBaseModal 
      size='xl' isOpen={ this.props.showUpdateClientModal }
      onClose={ () => this.props.onChangeClientHOC( 'showUpdateClientModal', false )}
    >
      <ClientForm
        users={ this.props.users }
        selectedClient={ this.props.selectedClient }
        onSubmit={ val => {
          let tmp = _.cloneDeep( val )
          tmp.styles = JSON.parse( tmp.styles )
          this.props.updateClient( tmp )
        }}
      />
      { this.props.onLoadClients && <LoadingOverlay />}
    </TechBaseModal>
  )

  renderCurrenciesModal = () => (
    <TechBaseModal 
      size='lg' isOpen={ this.props.showCurrencyListModal }
      onClose={ () => this.props.onChangeClientHOC( 'showCurrencyListModal', false )}
    >
      <CurrenciesConfig selectedClient={ this.props.selectedClient }/>
      { this.props.onLoadClients && <LoadingOverlay />}
    </TechBaseModal>
  )

  render = () => {

    return (
      <>
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <div className='page-action-bar'>
                    <div className="page-action-buttons">
                      <Button 
                        color='primary'
                        onClick={ () => this.props.onChangeClientHOC( 'showCreateClientModal', true ) } 
                      >
                        <div className='d-flex align-items-center'>
                          <FaPlus className='mr-2' style={{ width: 15, height: 15 }}/>
                          { CREATE }
                        </div>
                      </Button>
                    </div>
                  </div>
                  { this.renderClientTable() }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        {
          this.renderCreateClientForm()
        }
        {
          this.renderUpdateClientForm()
        }
        {
          this.renderCurrenciesModal()
        }
        { this.props.onLoadClients && <LoadingOverlay />}
        <ConfirmationModal
          open={ this.props.showDeleteClientModal }
          title={ ACTIONS }
          loading={ this.props.onLoadClients }
          message={ ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CLIENT }
          onClose={ () => this.props.onChangeClientHOC( 'showDeleteClientModal', false )}
          onClickConfirm={ () => this.props.deleteClient( this.props.selectedClient.id )}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({ data: state })
export default compose(
  ClientsHOC,
  connect( mapStateToProps )
)(Clients)