import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { CREATE_SUCCESSFUL, DELETE_SUCCESSFUL, UPDATE_SUCCESSFUL } from 'locale/component-labels'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      siteRoles: [],
      selectedSiteRole: {},
      showCreateSiteRoleModal: false,
      showDeleteSiteRoleModal: false,
      showUpdateSiteRoleModal: false
    }

    onChangeSiteRoleHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getSiteRole = () => {
      Get(
        `/sites/${ this.props.selectedSite.id }/roles`,
        this.getSiteRoleSuccess,
        this.getSiteRoleError,
        this.load,
        true
      )
    }

    getSiteRoleSuccess = payload => this.setState({ siteRoles: payload })
    getSiteRoleError = error => requestError( error )

    getSelectedSiteRole = ( site_id, id ) => Get(
      `/sites/${ site_id }/roles/${ id }`,
      this.getSelectedSiteRoleSuccess,
      this.getSelectedSiteRoleError,
      this.load,
      true
    )
    getSelectedSiteRoleSuccess = payload => {
      let temp = _.cloneDeep( payload )
      delete temp.updatedAt
      delete temp.createdAt

      this.setState({ selectedSiteRole: temp })
    }
    getSelectedSiteRoleError = error => requestError ( error )

    createSiteRole = ( site_id, dataToSubmit ) => Post(
      `/sites/${ site_id }/roles`,
      { siteId: site_id, ...dataToSubmit },
      this.createSiteRoleSuccess,
      this.createSiteRoleError,
      this.load
    )
    createSiteRoleSuccess = () => {
      this.getSiteRole()
      this.setState({ showCreateSiteRoleModal: false })
      requestSuccess( CREATE_SUCCESSFUL )
    }
    createSiteRoleError = error => requestError( error )

    updateSiteRole = ( site_id, dataToSubmit ) => Put(
      `/sites/${ site_id }/roles/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateSiteRoleSuccess,
      this.updateSiteRoleError,
      this.load
    )
    updateSiteRoleSuccess = payload => {
      this.getSiteRole()
      this.setState({ 
        showUpdateSiteRoleModal: false
      })
      requestSuccess( UPDATE_SUCCESSFUL )
    }
    updateSiteRoleError = error => requestError( error )

    deleteSiteRole = ( site_id, id ) => Delete(
      `/sites/${ site_id }/roles/${ id }`,
      this.deleteSiteRoleSuccess,
      this.deleteSiteRoleError,
      this.load
    )
    deleteSiteRoleSuccess = () => {
      this.getSiteRole()
      this.setState({
        showDeleteSiteRoleModal: false
      })
      requestSuccess( DELETE_SUCCESSFUL )
    }
    deleteSiteRoleError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadSiteRoles={ this.state.loading }
          siteRoles={ this.state.siteRoles }
          selectedSiteRole={ this.state.selectedSiteRole }
          showCreateSiteRoleModal={ this.state.showCreateSiteRoleModal }
          showDeleteSiteRoleModal={ this.state.showDeleteSiteRoleModal }
          showUpdateSiteRoleModal={ this.state.showUpdateSiteRoleModal }
          showUpdatePasswordModal={ this.state.showUpdatePasswordModal }
          getSiteRole={ this.getSiteRole }
          getSelectedSiteRole={ this.getSelectedSiteRole }
          createSiteRole={ this.createSiteRole }
          updateSiteRole={ this.updateSiteRole }
          deleteSiteRole={ this.deleteSiteRole }
          onChangeSiteRoleHOC={ this.onChangeSiteRoleHOC } />
      )
    }
  }
  return WithHOC
}

export default HOC