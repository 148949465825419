import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { connect } from 'react-redux'
// import { storeLastView } from 'actions/lastView'
import { EMAIL, GENDER, USERNAME } from 'locale/users-label'
import { CREATE_SUCCESSFUL, DELETE_SUCCESSFUL, UPDATE_SUCCESSFUL } from 'locale/component-labels'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      users: [],
      selectedUser: {},
      searchParams: {
        main: [
          { 
            label: USERNAME, 
            value: 'username',
            type: 'text',
            param: ''
          },
        ],
        advance: [
          { 
            label: EMAIL, 
            value: 'email',
            checked: false,
            type: 'text',
            param: ''
          },
          { 
            label: GENDER, 
            value: 'gender',
            checked: false,
            type: 'text',
            param: ''
          }
        ]
      },
      showCreateUserModal: false,
      showDeleteUserModal: false,
      showUpdateUserModal: false,
      showUpdatePasswordModal: false,
      newPassword: {
        password: '',
        password_confirmation: ''
      }
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getUser = query => {
      Get(
        `/users`,
        this.getUserSuccess,
        this.getUserError,
        this.load
      )
    }

    getUserSuccess = payload => this.setState({ users: payload })
    getUserError = error => requestError( error )

    getSelectedUser = id => Get(
      `/users/${ id }`,
      this.getSelectedUserSuccess,
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = payload => {
      let temp = _.cloneDeep( payload )
      delete temp.updatedAt
      delete temp.createdAt

      this.setState({ selectedUser: temp })
    }
    getSelectedUserError = error => requestError ( error )

    createUser = dataToSubmit => Post(
      `/users`,
      { ...dataToSubmit, siteRoleId: null },
      this.createUserSuccess,
      this.createUserError,
      this.load
    )
    createUserSuccess = () => {
      this.getUser()
      this.setState({ showCreateUserModal: false })
      requestSuccess( CREATE_SUCCESSFUL )
    }
    createUserError = error => requestError( error )

    updateUser = dataToSubmit => Put(
      `/users/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateUserSuccess,
      this.updateUserError,
      this.load
    )
    updateUserSuccess = payload => {
      this.getUser()
      this.getSelectedUser( payload.id )
      this.setState({ 
        showUpdateUserModal: false
      })
      requestSuccess( UPDATE_SUCCESSFUL )
    }
    updateUserError = error => requestError( error )

    deleteUser = ( id ) => Delete(
      `/users/${ id }`,
      this.deleteUserSuccess,
      this.deleteUserError,
      this.load
    )
    deleteUserSuccess = () => {
      this.getUser()
      this.setState({
        showDeleteUserModal: false
      })
      requestSuccess( DELETE_SUCCESSFUL )
    }
    deleteUserError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          searchParams={ this.state.searchParams }
          onLoadUsers={ this.state.loading }
          users={ this.state.users }
          selectedUser={ this.state.selectedUser }
          showCreateUserModal={ this.state.showCreateUserModal }
          showDeleteUserModal={ this.state.showDeleteUserModal }
          showUpdateUserModal={ this.state.showUpdateUserModal }
          showUpdatePasswordModal={ this.state.showUpdatePasswordModal }
          newPassword={ this.state.newPassword }
          getUser={ this.getUser }
          getSelectedUser={ this.getSelectedUser }
          createUser={ this.createUser }
          updateUser={ this.updateUser }
          deleteUser={ this.deleteUser }
          // updateUserPassword={ this.updateUserPassword }
          onChangeUserHOC={ this.onChangeUserHOC } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC