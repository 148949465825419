import React from 'react'
import { Button } from 'reactstrap'

export default function TechBaseModalFooter({
  rightButton = [],
  leftButton = [],
}) {

  return (
    <div 
      className="w-100 d-flex"
      style={{ position: 'absolute', left: 0, bottom: -40 }}
    >
      <div className="tech-base-footer-left-cont">
        { leftButton.map(( button, buttonIndex ) => (
          <Button
            key={ `left_btn_${ buttonIndex}` }
            color={ button.color || 'primary' }
            size={ 'xl' }
            onClick={ () => button.onClick() }
            disabled={ button.disabled || false }
            className={ `${ button.className || '' } ml-2` }
            style={{ ... button.style }}
          >
            { button.content }
          </Button>
        ))}
      </div>
      <div className="tech-base-footer-right-cont ml-auto">
        { rightButton.map(( button, buttonIndex ) => (
          <Button
            key={ `right_btn_${ buttonIndex}`}
            color={ button.color || 'primary' }
            size={ 'xl' }
            disabled={ button.disabled || false }
            onClick={ () => button.onClick()}
            className={ `${ button.className || '' } ml-2` }
            style={{ ... button.style }}
          >
            { button.content }
          </Button>
        ))}
      </div>
    </div>
  )
}
