import { combineReducers } from "redux"

import ThemeOptions from "./ThemeOptions"
import AjaxReducer from "./Ajax"
import AuthReducer from "./Auth"
import ProfileReducer from './profile'
import TranslationsReducer from './Translations'

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  AuthReducer,
  ProfileReducer,
  TranslationsReducer
})
