import React, { useState, useEffect } from 'react'
import { Card, CardBody, FormGroup, Label, Row, Col, Input } from 'reactstrap'
import _ from 'lodash'

import TechBaseModalBody from 'components/Modal/body'
import TechBaseModalFooter from 'components/Modal/footer'
import { SUBMIT } from 'locale/component-labels'
import { NAME, REMARK } from 'locale/sites-label'

const SiteRoleForm = props => {
  const [ siteRoleForm, updateSiteRoleForm ] = useState({ name: "", remark: "" })
  
  useEffect(()=>{
    if( props.selectedSiteRole ){
      updateSiteRoleForm( props.selectedSiteRole )
    }
  }, [ props.selectedSiteRole ])
  
  const onChangeField = ( context, val ) => {
    let tmp = _.cloneDeep( siteRoleForm )
    if( val !== undefined ){
      tmp[ context ] = val 
    } else {
      delete tmp[ context ]
    }
    updateSiteRoleForm( tmp )
  }
  
  return (
    <>
      <TechBaseModalBody>
        <Card>
          <CardBody>
            <Row>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ NAME }</Label>
                  <Input
                    value={ siteRoleForm.name }
                    onChange={ e => onChangeField( 'name', e.target.value ) }/>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label>{ REMARK }</Label>
                  <Input
                    value={ siteRoleForm.remark }
                    onChange={ e => onChangeField( 'remark', e.target.value ) }/>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </TechBaseModalBody>
      <TechBaseModalFooter
        rightButton={[
          {
            color: 'primary',
            content: SUBMIT,
            onClick: () => props.onSubmit( siteRoleForm )
          }
        ]}
      />
    </>
  )
}

export default SiteRoleForm