import React, { Component } from 'react'
import _ from 'lodash'
import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import { connect } from 'react-redux'
// import { storeLastView } from 'actions/lastView'
import { CREATE_SUCCESSFUL, DELETE_SUCCESSFUL, UPDATE_SUCCESSFUL } from 'locale/component-labels'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      users: [],
      clients: [],
      selectedClient: {},
      showCreateClientModal: false,
      showDeleteClientModal: false,
      showUpdateClientModal: false,
      showCurrencyListModal: false
    }

    onChangeClientHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getUser = () => {
      Get(
        `/users`,
        this.getUserSuccess,
        this.getUserError,
        this.load
      )
    }

    getUserSuccess = payload => {
      this.setState({ users: _.map( payload, user => { return { label: user.username, value: user.id }}) })
    }
    getUserError = error => requestError( error )

    getClient = () => {
      Get(
        `/clients`,
        this.getClientSuccess,
        this.getClientError,
        this.load
      )
    }

    getClientSuccess = payload => this.setState({ clients: _.map( payload, "client" ) })
    getClientError = error => requestError( error )

    getSelectedClient = id => Get(
      `/clients/${ id }`,
      this.getSelectedClientSuccess,
      this.getSelectedClientError,
      this.load
    )
    getSelectedClientSuccess = payload => {
      let temp = _.cloneDeep( payload.client )
      delete temp.updatedAt
      delete temp.createdAt

      this.setState({ selectedClient: temp })
    }
    getSelectedClientError = error => requestError ( error )

    createClient = dataToSubmit => Post(
      `/clients`,
      dataToSubmit,
      this.createClientSuccess,
      this.createClientError,
      this.load
    )
    createClientSuccess = () => {
      this.getClient()
      this.setState({ showCreateClientModal: false })
      requestSuccess( CREATE_SUCCESSFUL )
    }
    createClientError = error => requestError( error )

    updateClient = dataToSubmit => Put(
      `/clients/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateClientSuccess,
      this.updateClientError,
      this.load
    )
    updateClientSuccess = payload => {
      this.getClient()
      this.getSelectedClient( payload.id )
      this.setState({ 
        showUpdateClientModal: false
      })
      requestSuccess( UPDATE_SUCCESSFUL )
    }
    updateClientError = error => requestError( error )

    deleteClient = ( id ) => Delete(
      `/clients/${ id }`,
      this.deleteClientSuccess,
      this.deleteClientError,
      this.load
    )
    deleteClientSuccess = () => {
      this.getClient()
      this.setState({
        showDeleteClientModal: false
      })
      requestSuccess( DELETE_SUCCESSFUL )
    }
    deleteClientError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadClients={ this.state.loading }
          users={ this.state.users }
          clients={ this.state.clients }
          selectedClient={ this.state.selectedClient }
          showCreateClientModal={ this.state.showCreateClientModal }
          showDeleteClientModal={ this.state.showDeleteClientModal }
          showUpdateClientModal={ this.state.showUpdateClientModal }
          showCurrencyListModal={ this.state.showCurrencyListModal }
          getUser={ this.getUser }
          getClient={ this.getClient }
          getSelectedClient={ this.getSelectedClient }
          createClient={ this.createClient }
          updateClient={ this.updateClient }
          deleteClient={ this.deleteClient }
          // updateClientPassword={ this.updateClientPassword }
          onChangeClientHOC={ this.onChangeClientHOC } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC