import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'

import { MainNav } from './dataMainNav'

class VerticalNavWrapper extends Component {

  //  state = {
  //   readMenu: []
  // }

  // componentDidMount = () => {
  //   if ( this.props.data.PermissionReducer?.permisisonList?.permissions ){
  //     this.setState({ 
  //       readMenu: this.readPermissionHandler()
  //     })
  //   }
  // }

  // componentDidUpdate = pp => {
  //   if ( pp.data.PermissionReducer.permisisonList !== this.props.data.PermissionReducer.permisisonList ){
  //     this.setState({ 
  //       readMenu: this.readPermissionHandler()
  //     })
  //   }
  // }


  // readPermissionHandler = () => {
  //   const { permissions } = this.props.data.PermissionReducer.permisisonList

  //   let tempID = []
  //   let tempMenu = []
    
  //   permissions.map( childPermission => {
  //     if ( childPermission.action.read ){
  //       tempID.push( childPermission.reference_id )
  //     }
  //   })
    
  //   MainNav().map( subNav => {
  //     if ( tempID.indexOf( subNav.module_id ) > -1 ){
  //       tempMenu.push( subNav )
  //     } 
  //   })

  //   return tempMenu
  // }

  render = () => {
    return (
      <>
        <br />
        <MetisMenu
          content={ MainNav() }
          onSelected={ () => this.props.setEnableMobileMenu( !this.props.enableMobileMenu ) }
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down" />
      </>
    )
  }
}

const mapStateToProps = state => ({
  data: state,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu
})

export default connect( mapStateToProps, {
  setEnableMobileMenu
})( VerticalNavWrapper )