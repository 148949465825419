import { 
  STORE_TRANSLATIONS
 } from 'actions/types'

const initialState = {
  translations: []
}

export default ( state = initialState, actions ) => {

  switch ( actions.type ) {
    case STORE_TRANSLATIONS:
      return ({
        ...state,
        translations: actions.payload
      })
  
    default: return state
  }
}
