import { USERS, CLIENTS, SITES } from 'locale/side-menu-labels'

export const MainNav = () => [
  {
    icon: 'pe-7s-users',
    label: USERS,
    to: "#/dashboard/users"
  },
  {
    icon: 'pe-7s-id',
    label: CLIENTS,
    to: "#/dashboard/clients"
  },
  {
    icon: 'pe-7s-display1',
    label: SITES,
    to: "#/dashboard/sites"
  }
]