import React from 'react'
import { 
  Form, Label, FormGroup,
  Row, Col
} from 'reactstrap'
import { Button, Card, Input } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import _ from 'lodash'

import { NAME, ADD_NEW_PAYMENT_GATEWAY } from 'locale/clients-label'

const PaymentGatewayForm = props => {
  let {
    clientForm,
    onChangeField
  } = props

  return(
    <Form>
      <Button 
        onClick={ () => {
          let tmp = _.cloneDeep( clientForm.paymentGateway )
          tmp.push( { name: "" } )
          onChangeField( 'paymentGateway', tmp )
        }}>
        { ADD_NEW_PAYMENT_GATEWAY }
      </Button>
      <Row>
        {
          clientForm.paymentGateway.map( ( paymentGateway, index ) => (
            <Col sm={6}>
              <Card className="my-3">
                <Button 
                  type="primary" 
                  shape="circle" 
                  danger
                  className='d-flex justify-content-center align-items-center'
                  style={{ flexWrap: 'wrap', position: 'absolute', top: '-15px', right: '-15px' }}
                  onClick={ () => {
                    let tmp = _.cloneDeep( clientForm.paymentGateway )
                    tmp.splice( index, 1 )
                    onChangeField( 'paymentGateway', tmp )
                  }}>
                  <DeleteFilled />
                </Button>
                <FormGroup>
                  <Label>{ NAME }</Label>
                  <Input
                    value={ paymentGateway.name }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.paymentGateway )
                      tmp[ index ].name = e.target.value
                      onChangeField( 'paymentGateway', tmp )
                    }}/>
                </FormGroup>
              </Card>
            </Col>
          ))
        }
      </Row>
    </Form>
  )
}

export default PaymentGatewayForm