import React from 'react'
import {
  ModalBody
} from 'reactstrap'

export default function TechBaseModalBody({
  children,
  style
}) {
  

  return (
    <ModalBody className='tech-base-dialog-content' style={ style }>
      { children }
    </ModalBody>    
  )
}
