export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL'
export const AJAX_CALL_SUCCESS = 'AJAX_CALL_SUCCESS'
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR'

export const GET_PROFILE = 'GET_PROFILE'

export const STORE_LAST_VIEW = 'STORE_LAST_VIEW'

export const GET_DESIGNATION = 'GET_DESIGNATION'
export const GET_DICTIONARY_ROLE = 'GET_DICTIONARY_ROLE'

export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export const STORE_ROLES = 'STORE_ROLES'
export const STORE_STATUS = 'STORE_STATUS'
export const STORE_SECTION_CATEGORY = 'STORE_SECTION_CATEGORY'

export const UPDATE_LANG = 'UPDATE_LANG'
export const STORE_TRANSLATIONS = 'STORE_TRANSLATIONS'

export const GET_PERMISSION_LIST = 'GET_PERMISSION_LIST'
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";