import React from 'react'
import { 
  Form, Label, FormGroup,
  Row, Col, CustomInput
} from 'reactstrap'
import { Input, Select, Button } from 'antd'
import { EditOutlined, CloseCircleFilled } from '@ant-design/icons'
import _ from 'lodash'

import { LANGUAGE, LOGO, NAME, POINT_NAME, WEBSITE_URL, USER, REQUIRED_TO_BUY_PRODUCT_UPON_REGISTRATION, REQUIRED_TO_BUY_PACKAGE_UPON_REGISTRATION } from 'locale/clients-label'
import { LanguageOptions } from '../assets'
import UploadImage from './UploadImage'

const GeneralForm = props => {
  let {
    users,
    clientForm,
    onChangeField
  } = props
  
  return(
    <Form>
      <Row>
        <Col sm={6}>
          <FormGroup>
            <Label>{ NAME }</Label>
            <Input
              value={ clientForm.name }
              onChange={ e => onChangeField( 'name', e.target.value ) }/>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label>{ LANGUAGE }</Label>
            <Select
              mode="multiple"
              allowClear
              value={ clientForm.language }
              style={{ width: '100%' }}
              options={ LanguageOptions }
              onChange={ val => onChangeField( 'language', val ) }/>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label>{ WEBSITE_URL }</Label>
            <Input
              value={ clientForm.websiteUrl }
              onChange={ e => onChangeField( 'websiteUrl', e.target.value ) }/>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label>{ USER }</Label>
            <Select
              value={ clientForm.userId }
              style={{ width: '100%' }}
              options={ users }
              onChange={ val => onChangeField( 'userId', val ) }/>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Label>{ POINT_NAME }</Label>
            <Input
              value={ clientForm.pointName }
              onChange={ e => onChangeField( 'pointName', e.target.value ) }/>
          </FormGroup>
          <FormGroup>
            <CustomInput
              id='isSellProductAtRegistration'
              type='checkbox'
              checked={ clientForm.isSellProductAtRegistration }
              onChange={ e => onChangeField( 'isSellProductAtRegistration', e.target.checked ) } >
              { REQUIRED_TO_BUY_PRODUCT_UPON_REGISTRATION }
            </CustomInput>
          </FormGroup>
          <FormGroup>
            <CustomInput
              id='isSellPackageAtRegistration'
              type='checkbox'
              checked={ clientForm.isSellPackageAtRegistration }
              onChange={ e => onChangeField( 'isSellPackageAtRegistration', e.target.checked ) } >
              { REQUIRED_TO_BUY_PACKAGE_UPON_REGISTRATION }
            </CustomInput>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <div>
              <Label className='d-flex align-items-center'>
                { LOGO }
                {
                  clientForm.logoImageUrl && clientForm.logo && (
                    <CloseCircleFilled
                      className="ml-1"
                      style={{ color: "#FF4D4F" }}
                      onClick={ () => onChangeField( 'logo', undefined ) }/>
                  )
                }
              </Label>
            </div>
            {
              clientForm.logoImageUrl && !clientForm.logo ? (
                <div style={{ width: "150px", height: "150px", position: "relative" }}>
                  <img style={{ width: "150px", height: "150px", objectFit: "contain" }} src={ clientForm.logoImageUrl }/>
                  <Button 
                    style={{ position: "absolute", top: "0", right: "0" }}
                    icon={<EditOutlined />}
                    onClick={ () => onChangeField( 'logo', { base64: "", filename: "" } ) }/>
                </div>
              ) : (
                <>
                  <Input
                    value={ clientForm.logo?.filename }
                    placeholder={ NAME }
                    onChange={ e => {
                      let tmp = _.cloneDeep( clientForm.logo )
                      tmp.filename = e.target.value
                      onChangeField( 'logo', tmp ) 
                    }}/>
                  <UploadImage clientForm={ clientForm } onChangeField={ onChangeField }/>
                </>
              )
            }
          </FormGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default GeneralForm