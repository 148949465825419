import React, { useEffect } from "react";
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom"

import Login from './containers/Login'
import Dashboard from './containers/Dashboard'

import { getItem } from 'utils/tokenStore'
import { getProfile } from 'actions/profile' 

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !getItem( 'TECHBASE_TOKEN' )
      ? <Redirect to='/login' />
      : <Component {...props} />
  )} />
);

const CustomRouter = ({ location, ... props }) => {
  useEffect(()=>{
    if( getItem( 'TECHBASE_TOKEN' ) ){
      props.getProfile()
    }
  },[])
  return (
    <Switch>
      <Route exact path="/login" component={ Login } initialPath />
      <PrivateRoute path="/dashboard" component={ Dashboard } />
      <Redirect from={ '/' } to={ '/login' } />
    </Switch>
  )
}

const mapStateToProps = state => ({ data: state })
export default compose(
  connect( mapStateToProps, { getProfile } ),
  withRouter
)( CustomRouter )