export const USER = "User"
export const NAME = "Name"
export const GENERAL = "General"
export const STYLES = "Styles"
export const TIERS = "Tiers"
export const CONDITIONS = "Conditions"
export const PROFIT_TYPE = "Profit Type"
export const POINTS_ACCUMULATED = "Points Accumulated"
export const PERIOD_AMOUNT = "Period Amount"
export const PERIOD_UNIT = "Period Unit"
export const PERIOD_TIMES = "Period Times"
export const LANGUAGE = "Language"
export const PAYMENT_GATEWAY = "Payment Gateway"
export const LOGO = "Logo"
export const WEBSITE_URL = "Website URL"
export const POINT_NAME = "Point Name"
export const MAILGUN_API_KEY = "Mailgun API Key"
export const ENGLISH = "English"
export const MANDARIN = "Mandarin"
export const MALAY = "Malay"
export const ADD_NEW_TIER = "Add New Tier"
export const ADD_NEW_CURRENCY = "Add New Currency"
export const ADD_NEW_PAYMENT_GATEWAY = "Add New Payment Gateway"
export const UPLOADING = "Uploading"
export const UPLOAD = "Upload"
export const ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CLIENT = "Are you sure to delete the selected client?"
export const CURRENCIES = "Currencies"
export const EXCHANGE_RATE = "Exchange Rate"
export const COUNTRY_CODE = "Country Code"
export const ARE_YOU_SURE_TO_DELETE_THE_SELECTED_CURRENCIES = "Are you sure to delete the selected currencies?"
export const REQUIRED_TO_BUY_PRODUCT_UPON_REGISTRATION = "Required to buy product upon registration"
export const REQUIRED_TO_BUY_PACKAGE_UPON_REGISTRATION = "Required to buy package upon registration"
export const KEY = "Key"